import Helmet from "react-helmet"
import React from "react"
import AmazonContent from "src/components/amazoncontent"
import HomepageLayout from "src/layouts/homepage"
import CancelModal from "src/components/cancelmodal"
import FooterLinks from "src/components/footerlinks"

import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


var homepageData = import("./homepage.json");

function Cta(props) {
  return <button data-cy="cta" onClick = {() => window.triggerInstall()}><span>Add to {props.browser}. Its Free.</span></button>;
}

export default function HomePage() {
  let module = null;
  if(isFirefox()){
    module = <Cta browser="Firefox"></Cta>;
  }
  if(isChrome()){
    module = <Cta browser="Chrome"></Cta>;
  }
    if(isIeEdge()){
        module = <Cta browser="Edge"></Cta>;
    }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`

        `}</style>

        <title>Is Amazon Actually Giving You The Best Price</title>
</Helmet>
      <AmazonContent data={homepageData}>{module}
      </AmazonContent>
      <FooterLinks></FooterLinks>

      <CancelModal></CancelModal>
    </HomepageLayout>
  )
}
