import React from "react"
import HomepageData from "./data/extendedhomepage.json"
import Styles from "./css/extendedhomepage.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class ExtendedHomepage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: HomepageData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
    });
  }

  render() {
    return (
      <div className = {Styles.extendedhomepage}>

      <div className = {Styles.header}>

            <div className = {Styles.headerwrapper}>

              <div className = {Styles.logodiv}>
                <p className={Styles.ftcDisclosure}>This post is sponsored by Capital One Shopping</p>
                <img className = {Styles.toplogo} src={this.state.data.logo}/>
              </div>
              {this.props.children}
            </div>
      </div>

      <div className = {Styles.allContainer}>

      <h2>The #1 Thing You Should Know Before Renewing Amazon Prime</h2>
      <p className = {Styles.date}>September 15, 2022</p>
      <h3>Looking to renew Amazon Prime? Here’s the #1 thing that you absolutely MUST know first.</h3>

      <p>
      <img src='/assets/amazon_packagerainbow_1.jpeg'/>
      </p>

      <h3>Now, if you’re like me, you probably go on Amazon and assume that all the prices must be the lowest in the market. Or, if you’re like a bunch of my friends, you probably go on Amazon, search for your product, and then head to 10 other different sites to find the lowest price. Or, you could be somewhere in the middle.</h3>
      <h3>Regardless, there’s one thing every single one of us has in common - we all love a good deal.</h3>
      <h3>Now, what if… you could compare prices of products across sites, with just one press of a button?</h3>

      <h3>How much do you think you would save annually?</h3>

      <h3>Probably a lot. In fact, probably so much that Amazon is now shaking in their boots, anticipating what I’m about to show you. (And hoping that I won’t spill the beans.)</h3>
      <p>
      <img src='/assets/shop_genius_mixer.jpg'/>
      </p>

      <h3>With Capital One Shopping, you can shop online and start saving. Instantly. On almost everything.</h3>

<h3>All you have to do is add the extension to your browser - whether that’s Google Chrome, Mozilla Firefox, Microsoft Edge, or Safari. Just add it to your browser, and it’ll get to work for you!</h3>

<h3>It’s like having a personalized shopper to find all the best deals for you.</h3>

<h3>Here’s how it works: If a better offer is found for the product you’re looking at on your screen, you’ll receive a pop-up notification that shows you the better offer (including tax and shipping fees) AND compares both of them for you. And, if there are coupon codes available, it’ll apply the best one to your shopping cart instantly. </h3>

<h3>And the best part is… it’s totally FREE! There’s no catch, just great deals and great savings for you.</h3>

<h3>Ready to join the millions of customers who saved more than $160 million in 2021?</h3>

        <div className = {Styles.footcta}>
          <div className = {Styles.btnname}>
          <button data-cy="cta" onClick = {() => window.triggerInstall()}>START SAVING NOW</button></div>

          <div className = {Styles.rating}>
          <div className = {Styles.ratinglabel}>6,000,000+ users, Chrome Web Store</div>

          <div className = {Styles.ratingstars}>

            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            </div>
            </div>
            <span></span>

        </div>

      </div> {/*end allContainer*/}

      <div className = {Styles.footer}>
        <div className = {Styles.foottitle}><strong>As reviewed by</strong></div>

        <div className = {Styles.footcompany}>
          <img width='100px' height='26px' src='/assets/forbes.png' onClick={() => window.open('https://www.forbes.com/advisor/banking/money-saving-apps-and-browser-extensions-for-online-shopping/')}/>
          <img width='122px' height='26px' src='/assets/fortune.png' onClick={() => window.open('https://fortune.com/2022/05/26/ways-to-protect-your-money-from-inflation/')}/>
          <img width='76px' height='30px' src='/assets/usatoday.png' onClick={() => window.open('https://www.usatoday.com/story/tech/talkingtech/2019/02/28/wikibuy-google-bing-searches-can-help-you-save-amazon-purchases/3017747002/')}/>
          <img width='117px' height='20px' src='/assets/mashable.png' onClick={() => window.open('https://mashable.com/deals/may-17-capital-one-shopping')}/>
        </div>
      </div>

    </div>



    )
  }
}
export default ExtendedHomepage
